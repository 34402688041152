import { Link } from "react-router-dom"

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { addToCart } from "../services/backend";

export function CartIcon({ isAuthorized, inCart, itemId, toggleAuthForm }) {

  const Icon = isAuthorized && inCart ? ShoppingCartIcon : ShoppingCartOutlinedIcon

  return (
      isAuthorized ? (
        <Icon fontSize="small" onClick={() => addToCart(itemId)}/>
      ) : (
        <Icon fontSize="small"  onClick={() => toggleAuthForm()} />
      )
  )
}