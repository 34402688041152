import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  any: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  }
};

export function CarouselItem(props) {
  return (
    <img className="Carousel-item" src={props.image} alt="" href={props.url} />
  )
}

export function ProductCarousel(props) {
  return (
    <Carousel className='Some-style' responsive={responsive} centerMode={false} infinite={true}>
      {props?.items?.map(
        (item) => (
          <CarouselItem key={item.id} image={item.photo}/>
        )
      )}
    </Carousel>
  )
}
