import { client } from "src/services/backendClient"


export async function register(username, password, firstName) {
  return (
    client.post("/auth/users/", {
      "username": username, 
      "password": password,
      "first_name": firstName,
    })
  )
}

export async function fetchUser(token) {
  return (
    client.get("/auth/users/me/", {
      Authorization: `Bearer ${token}`,
    })
  )
}

export async function login(username, password) {
  return (
    client.post("/auth/jwt/create", {
      // "email": email,
      "username": username, 
      "password": password 
    })
  )
}

// export async function logout(username, password) {
//   return (
//     apiClient.post("/auth/jwt/create", {
//       "username": username, 
//       "password": password 
//     })
//   )
// }
