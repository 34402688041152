export function ConfirmEmailForm(props) {
  
	return (
    <>
      <h2>Вход</h2>
      <input type="text" placeholder="email или номер телефона"/>
      <br />
      <input type="text" placeholder="пароль" />
      <br />
      <input type="checkbox" /> Запомнить пароль
      <br />
      <button>Войти</button>
    </>
	)
}
