import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export function CategoriesTreeItem({ handleClick, isNested, itemName }) {
  return (
    <div className="Row Categories-tree-item">
        <input type="checkbox" />
        <div className="Row" onClick={ handleClick }>
          <p>{itemName}</p>
          <p>{isNested ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</p>
        </div>
    </div>
  )
}