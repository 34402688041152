import logo from 'src/assets/logo.png'

import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { client } from "src/services/backendClient"
import { ModalWindow } from "src/components/ModalWindow"
import { FilterParams } from "src/pages/FilterParams"
import { UserMenu } from "./UserMenu"

export function AppHeader() {
  const [filterDisplay, setFilterDisplay] = useState(false)
  
  const [categories, setCategories] = useState([])
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    client.get('/categories')
         .then(res => {
          setCategories(res.data)
         })
         .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    // console.log(window.navigator.onLine)
    window.addEventListener('online', (e) => console.log('Became online'));
    window.addEventListener('offline', (e) => console.log('Became offline'));
    return () => {
      window.removeEventListener('online', (e) => console.log('Became online'))
      window.removeEventListener('offline', (e) => console.log('Became offline'));
    }
  }, [])

  return (
    <header className='App-header'>
      <div className='Header-content Element-adaptive'>
        <div style={{  }}>
          <Link to='/'><img src={logo} alt="Лого" style={{ objectFit: 'cover', height: '100%' }} /></Link>
        </div>
        
        <div className='Row' /*style={{backgroundColor: 'green'}}*/>
          <div className='Filter-button' onClick={()=>{setFilterDisplay(!filterDisplay)}}>Фильтры</div>
          <div className='Search-area'>
            <input type="text" value={searchString} className='Search-input' onChange={(event) => setSearchString(event.target.value)}/>
            <Link to='/'
                  state = {{
                    searchString: searchString
                  }}
                  >
              <button className='Search-button'>
                Найти
              </button>
            </Link>
          </div>
        </div>
        <div className='Header-auth Row' /*style={{backgroundColor: 'blue'}}*/>
          {/* <div><button>Кнопка</button></div>
          <div><button>Кнопка</button></div>
          <div><button>Кнопка</button></div> */}
        </div>
        <UserMenu/>
        <ModalWindow isVisible={filterDisplay} closeFunction={()=>{setFilterDisplay(!filterDisplay)}} fadeBackground={false}>
          <FilterParams categories={categories} />
        </ModalWindow>
      </div>
    </header>
  )
}