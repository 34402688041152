import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { client } from "src/services/backendClient";
import { login as loginCallApi } from "src/features/auth/services/backend";

export const AuthContext = createContext({
  user: JSON.parse(localStorage.getItem("user")),
  setUser: () => {},
});

export const useLocalStorage = () => {
  const [value, setValue] = useState(null);

  const setItem = (key, value) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  const getItem = (key) => {
    const value = localStorage.getItem(key);
    setValue(value);
    return value;
  };

  const removeItem = (key) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};

export const useUser = () => {
  const { user, setUser } = useContext(AuthContext);
  const { setItem, removeItem } = useLocalStorage();

  const addUser = (user) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    removeItem("user");
  };

  return { user, addUser, removeUser, setUser };
};

export const useAuth = () => {
  // we can re export the user methods or object from this hook
  const { user, addUser, removeUser, setUser } = useUser();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    // const user_ = {
    //   name: "Andrey",
    //   token: {
    //     refresh: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcxNDAzMzA3OCwiaWF0IjoxNzEzOTQ2Njc4LCJqdGkiOiI3NTYzODc5M2Q1ZDg0ZjA3YTBmMTczYmIyODc2ZTJjZCIsInVzZXJfaWQiOjF9.1bwR7zm3Hb4tU9ePpzzS15zuweZq3OouCr4tx6uak5A",
    //     access: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE2NTM4Njc4LCJpYXQiOjE3MTM5NDY2NzgsImp0aSI6IjM3MzFiMjY1YzMzZTQ3MmViN2ZjM2FkOTQ2NDllNDMyIiwidXNlcl9pZCI6MX0.KPhMTj-XmXRO6jywJykb76KCDCFSBYFXYHMw3yC0Evo"
    //   }
    // }
    // addUser(user_)
    const userString = getItem("user");
    
    if (userString) {
      addUser(JSON.parse(userString));
    }
    
    if (user) {
      client.defaults.headers.common["Authorization"] = "Bearer " + user.token.access
    } else {
      delete client.defaults.headers.common["Authorization"];
    }
  }, [addUser, getItem]);

  const login = async (username, password) => (
    loginCallApi(username, password).then(
      ({ data }) => {
        addUser({
          name: data.first_name,
          token: {
            refresh: data.refresh,
            access: data.access
          }
        })
        window.location.reload()
      }
    )
  );

  const logout = () => {
    removeUser();
    window.location.reload()
  };

  return { user, login, logout, setUser };
};


// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {

//   const [firstName, setFirstName] = useState(localStorage.getItem("firstname"))
//     const [token, setToken_] = useState(
//     { 
//       access: localStorage.getItem("access"),
//       refresh: localStorage.getItem("refresh"),
//     }
//   )

//   const setToken = (newAccessToken, newRefreshToken) => {
//     console.log('token refreshed')
//     setToken_(
//       { 
//         access: newAccessToken,
//         refresh: newRefreshToken,
//       }
//     )
//   }
  
//   const isAuthorised = () => {
//     return !(token.access == null)
//   }

//   const contextValue = useMemo(
//     () => ({
//       firstName,
//       isAuthorised,
//       token,
//       setToken,
//       setFirstName,
//     }),
//     [token]
//   )

//   useEffect(() => {
//     if (token.access != "null" && token.access != null) {
//       axios.defaults.headers.common["Authorization"] = "Bearer " + token.access
//       localStorage.setItem('access', token.access);
//       localStorage.setItem('refresh', token.refresh);
//       localStorage.setItem('firstname', firstName);
//     } else {
//       delete axios.defaults.headers.common["Authorization"];
//       localStorage.removeItem('access')
//       localStorage.removeItem('refresh')
//       localStorage.removeItem('firstname')
//     }
//   }, [contextValue]);

//   return (
//     <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   return useContext(AuthContext);
// };

// export default AuthProvider;