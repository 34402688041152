export function Message({ signLetter, messageText, time, isSent }) {
  return (

    <div className={`Message-row  ${isSent ? "Sent" : ""}`}>
      <span className="Message-avatar">{signLetter}</span>
      <div className="Box Message-textbox">
        <span>{messageText}</span>
      </div>
      <p className="Message-timestamp">{time}</p>
    </div>
  )
}