import { useState } from "react"

import { CategoriesTreeItem } from "./CategoriesTreeItem"

export function CategoriesTree({ categories }) {
  const [nestingArray, setNestingArray] = useState([-1, -1, -1, -1, -1])
  
  const setNestingValue = (index, newValue) => {
    let isShown = nestingArray[index] !== -1
    setNestingArray(() => [
      ...nestingArray.slice(0, index),
      isShown ? -1 : newValue,
      isShown ? nestingArray.slice(index + 1).map(() => -1) : nestingArray.slice(index + 1)
    ])
  }
  
  return (
    <TreeLevel items={categories}
                nestingArray={nestingArray}
                setNestingValue={setNestingValue}
                nesting_level={0} />
  )
}
  
function TreeLevel(props) {
  const { items, setNestingValue, nestingArray, nesting_level } = props

  return items ? (
    <div className="Row">
      <div className="Category-column">
        { items.map((item, index) => (
          <CategoriesTreeItem itemName={item.name} key={item.id} handleClick={() => setNestingValue(nesting_level, index)}
                              isNested={nestingArray[nesting_level] == index} />
        )) }
      </div>
      <TreeLevel items={items[nestingArray[nesting_level]]?.children}
                  nestingArray={nestingArray}
                  setNestingValue={setNestingValue}
                  nesting_level={nesting_level + 1} />
    </div>
  ) : <></>
}
