import { useEffect } from "react";

export function ModalWindow({ children, closeFunction, fadeBackground, isVisible, }) {

  useEffect(() => {
    document.body.style.overflow = isVisible ? "hidden" : "scroll"
  }, [isVisible]);

  return isVisible ? (
    <div className='Modal-window' 
         onClick = {closeFunction}
         style={{ backgroundColor: fadeBackground ? 'rgba(0, 0, 0, 0.6)' : 'none' }}>
      
      <div style={{ width: 'fit-content', margin: 'auto' }} onClick={ (e)=> { e.stopPropagation(); /*e.preventDefault()*/ } }>
        { children }
      </div>
    </div>
  ) : undefined
}

ModalWindow.defaultProps = {
  fadeBackground: true
}
