import { useState } from "react"

import { register } from "../services/backend"
import { useAuth } from "../../../context/AuthProvider"


export function RegisterForm(props) {

  const [firstname_, setFirstname_] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  
  // const { setToken, setFirstName } = useAuth()
  const { user, login } = useAuth()

  const submitForm = () => {
    register(username, password, firstname_).then(
      () => {
        login(username, password).then(
          res => {
            // setToken(res.data.access, res.data.refresh)
            // setFirstName(firstname_)
          }
        )
        .catch(err => {
          console.log(err)
        })
      }
    )
    .catch(err => {
      console.log(err)
    })
  }
  
  return (
    <>
      <h2>Регистрация</h2>
      <input className="Default-input Auth-form-input" 
             type="text" placeholder="Имя" value={firstname_} onChange={(e) => setFirstname_(e.target.value)} />
      <br />
      <input className="Default-input Auth-form-input"
             type="text" placeholder="email или номер телефона" value={username} onChange={(e) => setUsername(e.target.value)} />
      <br />
      <input className="Default-input Auth-form-input"
             type="password" placeholder="пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
      <br />
      <input className="Default-input Auth-form-input"
             type="password" placeholder="подтвердите пароль" value={confirmedPassword} onChange={(e) => setConfirmedPassword(e.target.value)} />
      <br />
      <input type="checkbox" /> Запомнить пароль
      <br />
      <div className="Buttons-row">
        <button className="Action-button" style={{ width: '150px' }} onClick={submitForm}>Зарегистриоваться</button>
      </div>
    </>
	)
}
