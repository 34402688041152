import { Link } from "react-router-dom"
import { WishlistIcon } from "./WishlistIcon"
import { CartIcon } from "./CartIcon"


export function ProductCard({product, isAuthorized, toggleAuthForm}) {
  return (
    <div className="Product-card-frame">
      <div style={{width: '100%', height: '100%', backgroundColor: '#c4a988', padding: '3px'}}>
        <Link to={`/products/${product.slug}`}>
          <img src={product.photos?.at(0)?.image} style={{height: '60%', width: '100%', objectFit: 'cover'}} alt="?"/>
        </Link>
        <div className='Product-card-data' style={{height: '40%', margin: '5px', /*backgroundColor: 'gray'*/}}>
          <p className='Product-card-title'>{product.name}</p>
          <div className="Row opposing" style={{ marginTop: '0px' }}>
            <p className='Product-card-price'>
              <span>{product.price}</span>
              &nbsp;
              <span>руб.</span>
            </p>
            <div className="Row" style={{ gap: '5px' }}>
              { <WishlistIcon isAuthorized={isAuthorized}
                              inWishlist={product.in_wishlist}
                              itemId={product.id}
                              toggleAuthForm={toggleAuthForm} /> }
              { <CartIcon     isAuthorized={isAuthorized}
                              inCart={product.in_cart}
                              itemId={product.id}
                              toggleAuthForm={toggleAuthForm} /> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductCard.defaultProps = {
  isAuthorized: false
}