import { createRef, useEffect, useState } from "react"
import { useLocation } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query"

import { useAuth } from "src/context/AuthProvider"
import { fetchData } from "src/services/backendClient"
import { ModalWindow } from "src/components/ModalWindow"
import { ProductCard } from "../features/home/components/ProductCard"
import { ProductCarousel } from "../features/home/components/ProductCarousel"
import { AuthForms } from "./AuthForms"

export function Home() {
  const { user } = useAuth()
  const location = useLocation();
  const [authFrameDisplay, setAuthFrameDisplay] = useState(false);
  const toggleAuthFrameDisplay = () => setAuthFrameDisplay(prev => !prev)

  const [products, setProducts] = useState([])

  // const { data: products, isLoading: productsLoading, isFetched } = useQuery({
  //   queryKey: ['getProducts'],
  //   queryFn: () => fetchData('/products', { searchString: location.state?.searchString } ),
  //   refetchOnWindowFocus: false,
  //   networkMode: 'always',
  // })

  const { data: carouselItems, carouselItemsLoading } = useQuery({
    queryKey: ['getSpecialOffers'],
    queryFn: () => fetchData('/special_offers')
  })

  useEffect(() => {
    console.log(location.state?.searchString)
    fetchData('/products', { searchString: location.state?.searchString }).then((data) => {
      setProducts(data)
    })
  }, [])

  return (
    <>
      { carouselItems && <ProductCarousel items={carouselItems}/> }
      <div className="Product-row">
        {
        [1, 2, 3, 4, 5, 6, 7].map(e => (
          products?.map(item => (
            <ProductCard key={e + item.id} product={item}
                                           isAuthorized={!!user}
                                           toggleAuthForm={toggleAuthFrameDisplay}  />
          ))
        ))
        }
      </div>
      { 
        <ModalWindow isVisible={authFrameDisplay} closeFunction={()=>{setAuthFrameDisplay(!authFrameDisplay)}}>
          <AuthForms />
        </ModalWindow>
      }
    </>
  )
}
