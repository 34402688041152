import 'src/assets/styles/AccountInfo.css'
import { useQuery } from '@tanstack/react-query';
import { fetchData } from 'src/services/backendClient';
import { UserInfo } from 'src/features/accountInfo/UserInfo';
import { StoreInfo } from 'src/features/accountInfo/StoreInfo';

export function AccountInfo() {

  const { data: user, userLoading } = useQuery({
    queryKey: ['getUserInfo'],
    queryFn: () => fetchData(`/auth/user_info/`)
  })

  return (
    <>
      <div className="Row" style={{ gap: '20px' }}>
        <p>Покупатель</p>
        <p>Продавец</p>
      </div>
      { 
        !userLoading && (
          user?.role === 'c' ? <UserInfo user={user} /> : <StoreInfo />
        )
      }
    </>
    
  )
}