import { useQuery } from '@tanstack/react-query';
import { fetchData } from 'src/services/backendClient';
import { ProductCard } from '../home/components/ProductCard';

export function UserInfo({ user }) {

  const { data: wishlistProducts, isLoading: wishlistProductsLoading, isFetched: isWishListFetched } = useQuery({
    queryKey: ['getWishlist'],
    queryFn: () => fetchData('/wishlist'),
  })

  const { data: cartProducts, isLoading: cartProductsLoading, isFetched: isCartFetched } = useQuery({
    queryKey: ['getCart'],
    queryFn: () => fetchData('/cart'),
    refetchOnWindowFocus: true,
    networkMode: 'always',
  })

  return (
    <>
      <div className="Account-header-grid">
        <div className='Account-header-grid-icon'>
          {
            user?.icon ? (
              <img src={user.icon} alt="icon" />
            ) : (
              <div className='No-icon'></div>
            )
          }
        </div>
        <div>
          <h3>{user?.first_name}</h3>
        </div>
      </div>
      <div className=''>
        <h3>Корзина:</h3>
        <div className="Product-row">
          {
            isCartFetched && (
              cartProducts ? cartProducts.map(item => (
                <ProductCard key={item.id} product={item} />
              )) : (
                <>Корзина пуста</>
              )
            )
          }
        </div>
      </div>
      <div className=''>
        <h3>Избранное:</h3>
        <div className="Product-row">
          {
            isWishListFetched && (
              wishlistProducts ? wishlistProducts.map(item => (
                <ProductCard key={item.id} product={item} />
              )) : (
                <>Нет товаров в избранном</>
              )
            )
          }
        </div>
      </div>
    </>
  )
}