import { useState } from "react"
import { AdaptiveFrame } from "../components/AdaptiveFrame"
import { CategoriesTree } from "../features/searchFilters/CategoriesTree"
import { StylesFrame } from "../features/searchFilters/StylesFrame"

import LocationOnIcon from '@mui/icons-material/LocationOn'
import BrushIcon from '@mui/icons-material/Brush'
import LayersIcon from '@mui/icons-material/Layers'
import TableViewIcon from '@mui/icons-material/TableView'

export function FilterParams({ categories }) {

  const [filterFrame, setFilterFrame] = useState()
  
  return (
    <AdaptiveFrame>
      <div className='Filter-params'>
        <div>
          <p className="Filter-category"><TableViewIcon />Каталог</p>
          <p className="Filter-category"><BrushIcon />Стиль</p>
          <p className="Filter-category"><LayersIcon />Материалы</p>
          <p className="Filter-category"><LocationOnIcon />Москва</p>
        </div>
        <div>
          <div className="Filter-area">
            <CategoriesTree categories={categories} />
            {/* <StylesFrame /> */}
          </div>
        </div>
      </div>
    </AdaptiveFrame>
  )
}
