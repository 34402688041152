import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const responsive = {
  any: {
    breakpoint: { max: 4000, min: 0 },
    items: 1
  }
};

const small = {
  any: {
    breakpoint: { max: 4000, min: 0 },
    items: 10
  }
};

export function ImagesCarousel({ photos }) {
  const [curImageIndex, setCurImageIndex] = useState(0)

  return (
    <div className="Product-page-gallery" /*style={{ backgroundColor: "green" }}*/>
      <div style={{ width: '75%', margin: 'auto', aspectRatio: '9 / 6', marginBottom: '20px' }}>
        <img style={{ objectFit: "contain", height: "100%" }} src={photos[curImageIndex].image} alt=""/>
      </div>
      <Carousel containerClass="Product-photos-small-carousel" itemClass="Product-photos-small-item" responsive={small}
                beforeChange={(nextSlide, { currentSlide, onMove }) => {
                  setCurImageIndex(nextSlide)
                }}>
      
      {
        photos.map(item => (
          <img style={{ objectFit: "scale-down", height: "100px" }} src={item.image} alt=""/>
        ))
      }
      </Carousel>
    </div>
  )
}