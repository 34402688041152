import axios from "axios";

import { BASE_URL } from "src/data";


export const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const fetchData = (link, params) => client.get(link, { params: params }).then(res => res.data)
