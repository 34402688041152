import { client } from "src/services/backendClient";


export const patchWishList = (id) =>
  client.patch('/wishlist', {
    productId: id
  }).then(res => res.data)

  export const addToCart = (id) =>
    client.patch('/cart', {
      productId: id
    }).then(res => res.data)