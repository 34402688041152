import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { useAuth } from "src/context/AuthProvider"
import { fetchData } from "src/services/backendClient"
import { ProductCard } from "../features/home/components/ProductCard";


export function Store({ id }) {
  const { user } = useAuth()
  const { data: products, isLoading: productsLoading } = useQuery({
    queryKey: ['getProductsByStore', id],
    queryFn: () => fetchData('/products', { storeId: id } ),
    refetchOnWindowFocus: false,
    networkMode: 'always',
  })

  const { data: store, isLoading: storeLoading } = useQuery({
    queryKey: ['stores', id],
    queryFn: () => fetchData(`/stores/${id}`),
    refetchOnWindowFocus: false,
    networkMode: 'always',
  })

  return (
    <>
      <h1> { store?.title } </h1>
      <div className="Store-info">
        <div>
          <h3>Описание:</h3>
          <p>{ store?.description }</p>
        </div>
        <div>
          {/* <h4>Информация:</h4>
          <br />
          <br />
          <br />
          <br />
          <br /> */}
        </div>
      </div>
      <h2>Товары:</h2>
      <div className="Product-row">
        {
          products?.map(item => (
            <ProductCard key={item.id}
                         product={item}
                         isAuthorized={!!user} />
          ))
        }
      </div>
    </>
  )
}

Store.defaultProps = {
  id: 1
}