import { createRef, useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { fetchData } from "src/services/backendClient"
import { ProductCard } from "../features/home/components/ProductCard"

export function WishList() {

  const { data: products, productsLoading, isFetched } = useQuery({
    queryKey: ['getWishlist'],
    queryFn: () => fetchData('/wishlist'),
  })

  return (
    <>
      <div className="Row">
      <h1>Избранное</h1>
      </div>
      <div className="Product-row">
        {
          products?.map(item => (
            <ProductCard key={item.id} product={item} />
          ))
        }
      </div>
    </>
  )
}
