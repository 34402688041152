import { Link } from "react-router-dom"

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { patchWishList } from "../services/backend";

export function WishlistIcon({ isAuthorized, inWishlist, itemId, toggleAuthForm }) {

  const Icon = isAuthorized && inWishlist ? FavoriteIcon : FavoriteBorderIcon

  return (
      isAuthorized ? (
        <Icon fontSize="small" onClick={() => patchWishList(itemId)}/>
      ) : (
        <Icon fontSize="small"  onClick={() => toggleAuthForm()} />
      )
  )
}