export function AccountsListingItem({ title, product, lastMessage }) {
  return (
    <div className="Messenger-listing-item Chat">
      <div className="Row">
        <div className="Chat-data">
          <span>{title}</span>
          <br />
          <span>{product}</span>
          <br />
          <span>{lastMessage}</span>
          <br />
        </div>
      </div>
    </div>
  )
}