import { useState } from "react";

import { LoginForm } from "../features/auth/components/LoginForm";
import { RegisterForm } from "../features/auth/components/RegisterForm";
import { ConfirmEmailForm } from "../features/auth/components/ConfirmEmailForm";
import { ConfirmPhoneForm } from "../features/auth/components/ConfirmPhoneForm";


export function AuthForms() {
  
  const [authStage, setAuthStage] = useState('Logging in');

	return (
    <div className="Auth-form-box" >
      { authStage === 'Logging in' && <LoginForm toRegisterForm={()=>{setAuthStage('Registration')}} /> }
      { authStage === 'Registration' && <RegisterForm setAuthStage={setAuthStage} /> }
      { authStage === 'Email confirmation' && <ConfirmEmailForm /> }
      { authStage === 'Phone confirmation' && <ConfirmPhoneForm /> }
    </div>
	)
}
