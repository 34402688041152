import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { AuthForms } from "../pages/AuthForms";
import { ModalWindow } from "../components/ModalWindow";
import { useAuth } from "../context/AuthProvider";


export function UserMenu() {
  const [authFrameDisplay, setAuthFrameDisplay] = useState(false);
  const { user, logout } = useAuth()

  useEffect(() => {
    // console.log(user)
  }, [user])

  return (
    <>
      <div className='User-menu-dropdown'>
        { 
          user ? (
            <div className="Row">
              <div className="Circle">{user.name[0]}</div>
              <span>{user.name}</span>
            </div>
          ) : <span onClick={()=>(setAuthFrameDisplay(!authFrameDisplay))}>Войти</span>
        }
        
        {
          user ? (
            <div className="User-menu-dropdown-content">
              <ul>
              <Link to='/me'><li>Профиль</li></Link>
              <Link to='/chats'><li>Сообщения</li></Link>
              <Link to='/wishlist'><li>Избранное</li></Link>
              <Link to='/cart'><li>Корзина</li></Link>
              <li onClick={logout}>Выйти</li>
              </ul>
            </div>
          ) : undefined
        }
      </div>
      { 
        <ModalWindow isVisible={authFrameDisplay} closeFunction={()=>{setAuthFrameDisplay(!authFrameDisplay)}}>
          <AuthForms />
        </ModalWindow>
      }
    </>
  )
}