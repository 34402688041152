import {createRef, useEffect, useState} from 'react'
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import './assets/styles/App.css';
import { AuthContext, useAuth  } from 'src/context/AuthProvider';

import { AdaptiveFrame } from './components/AdaptiveFrame';
import { AppHeader } from './layouts/Header';

import { Chat } from './pages/Chat';
import { Home } from './pages/Home'
import { Product } from './pages/Product';
import { Store } from './pages/Store';
import { WishList } from './pages/WishList';
import { Cart } from './pages/Cart';
import { AccountInfo } from './pages/AccountInfo';


function App() {
  // TODO: пересмотреть видос и сделать
  // хук с пушом ссылок в историю браузера
  
  const { user, login, logout, setUser } = useAuth();
  const app_ref = createRef();
  
  const location = useLocation();

  useEffect(() => {
    console.log('Current location is ', location.pathname);
  }, [location]);

  return (
      <div className='App' ref={app_ref}>
        <AuthContext.Provider value={{ user, setUser}} >
          <AppHeader />
          <AdaptiveFrame>
            <div style={{ backgroundColor: 'antiquewhite', minHeight: '80vh', padding: '10px', paddingTop: '20px' }}>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="me" element={<AccountInfo />} />
              <Route path="cart" element={<Cart />} />
              <Route path="wishlist" element={<WishList />} />
              <Route path="chats" element={<Chat />} />
              <Route path="products/:slug" element={<Product />} />
              <Route path="store/" element={<Store />} />
            </Routes>
            </div>
          </AdaptiveFrame>
        </AuthContext.Provider >
      </div>
  );
}

export { App };