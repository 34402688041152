import { createRef, useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { fetchData } from "src/services/backendClient"
import { ProductCard } from "src/features/cart/components/ProductCard"

export function Cart() {

  const { data: products, isLoading: productsLoading, isFetched } = useQuery({
    queryKey: ['getCart'],
    queryFn: () => fetchData('/cart'),
    refetchOnWindowFocus: true,
    networkMode: 'always',
  })

  return (
    <>
      <h1>Корзина</h1>
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      <button className='Filter-button'>
        Оформить
      </button>
      <div style={{ width: '100%' }}>
        {
          products ? products.map(item => (
            <ProductCard key={item.id} product={item} />
          )) : (
          <div>
            <h2>Корзина пуста</h2>
          </div>
        )
        }
      </div>
    </>
  )
}
