import { createRef, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from "@tanstack/react-query"

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlaceIcon from '@mui/icons-material/Place';
import SendIcon from '@mui/icons-material/Send';
import SellIcon from '@mui/icons-material/Sell';
import { TextareaAutosize } from "@mui/material";

import { useAuth } from 'src/context/AuthProvider';
import { fetchData } from "src/services/backendClient"
import { Message } from 'src/features/chat/components/Message';
import { AccountsListingItem } from 'src/features/chat/components/AccountsListingItem';


export function Chat() {

  const { user } = useAuth()
  const [ws, setWs] = useState();
  const [message, setMessage] = useState('');
  const [receivedMessage, setReceivedMessage] = useState('');
  // const [messages, setMessages] = useState([
    
  // ]);

  const [curChat, setCurChat] = useState("chat-lookup")

  const location = useLocation();

  const { data: chats } = useQuery({
    queryKey: ['getChats'],
    queryFn: () => fetchData('/chats').then(data => {
      if (location?.state) {
        data = [location.state, ...data]
      }
      return data
    })
  })

  const queryClient = useQueryClient();
  const { data: messages, refetch: refetchMessages } = useQuery({
    queryKey: ['getMessages'],
    queryFn: () => fetchData(`/chats`/*`/chats/${curChat}`*/)
  })

  const addMessage = (newMessage) => {
    queryClient.setQueryData('getMessages', (prevMessages) => {
      console.log(prevMessages)
      return [newMessage, ...(prevMessages ?? [])]
    });
    setMessage('')
  }
  
  useEffect(() => {
    console.log('chats:', chats)
    // if (!curChat) {
    //   return
    // }
    const ws = new WebSocket(`ws://127.0.0.1:8000/msg/?token=${user?.token.access}&chat=${curChat}`);
    setWs(ws)
    ws.onopen = () => {
        console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event) => {
        console.log('Received message:', event.data);
        addMessage(JSON.parse(event.data).message)
        setMessage('');
    };

    ws.onclose = () => {
        console.log('Disconnected from WebSocket server');
    };

    return () => {
        ws.close();
    };
  }, []);

  const sendMessage = () => {
    // Send message to server
    if (ws) {
      ws.send(JSON.stringify({ message, chat: curChat }));
      console.log('sent!')
    }
  };

  useEffect(() => {
    refetchMessages()
  }, [curChat])

  return (
    <div style={{ height: "87.5vh" }}>
    <div className="Messenger-grid" >
      
    <div className="Messenger-section" style={{ borderRight: "1px solid grey" }}>
      <div className="Scrollable-parent">
        <div className="Scrollable-area">
          {
            chats?.map((item) => (
              <AccountsListingItem {...item}/>
            ))
          }
        </div>
      </div>
    </div>

    <div className="Messenger-section">        
      <div className="Messenger-frame">
        <>
          <div className="Scrollable-parent Messages-column">
            {
              messages?.map((item, index) => (
                <Message signLetter={"А"} messageText={item} time={"16:24"} isSent={true} key={index} />
              ))
            }
          </div>
        </>
        <div className="Send-message-bar" style={{ height: "20px" }}>
          <AssignmentIcon color="primary" sx={{ marginBottom: "20px", marginLeft: "5px" }} />
          <TextareaAutosize maxRows={5} className="Box Send-message-input" placeholder="Отправить сообщение..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} />
          <SendIcon color="primary" sx={{ marginBottom: "20px" }} onClick={sendMessage} />
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}