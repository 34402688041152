import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';

import { fetchData } from 'src/services/backendClient';
import { ImagesCarousel } from 'src/features/product/components/ImagesCarousel';
import { WishlistIcon } from 'src/features/home/components/WishlistIcon';
import { CartIcon } from 'src/features/home/components/CartIcon';
import { useAuth } from 'src/context/AuthProvider';
import { AuthForms } from './AuthForms';
import { ModalWindow } from 'src/components/ModalWindow';


export function Product(props) {
	let { slug } = useParams();
	const { user } = useAuth()

	const [authFrameDisplay, setAuthFrameDisplay] = useState(false);
  const toggleAuthFrameDisplay = () => {setAuthFrameDisplay(prev => !prev) }

	const { data: product, productLoading } = useQuery({
    queryKey: ['getProducts'],
    queryFn: () => fetchData(`products/${slug}`)
  })

  // const { data: photos, photosLoading } = useQuery({
  //   queryKey: ['getSpecialOffers'],
  //   queryFn: () => fetchData('/special_offers')
  // })


	// const [product, setProduct] = useState({})
	// const [photos, setPhotos] = useState([])
	// useEffect(() => {
	// 	client.get(`products/${slug}`)
  //         .then(({data}) => {
	// 					setProduct(data)
	// 					setPhotos(data.photos)
	// 					// console.log(res.data)
  //       	})
  //        .catch(err => console.log(err))
	// }, [])

	return (
		<>
			<div className="Product-page-grid">
				<div className="Product-page-header" /*style={{ backgroundColor: "green" }}*/><h2>{product?.name}</h2></div>
				<div className="Product-page-price" /*style={{ backgroundColor: "green" }}*/><h2>{product?.price} руб.</h2></div>
				{ product?.photos && <ImagesCarousel photos={product?.photos} /> }
				<div className="Product-page-side-info" style={{ /*backgroundColor: "green"*/ }}>
					{/* <p>Характеристики</p> */}
					<br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

					<button className="Action-button" style={{ backgroundColor: '#9b6a6a' }}>
						<WishlistIcon isAuthorized={!!user}
                          inWishlist={product?.in_wishlist}
                          itemId={product?.id}
													toggleAuthForm={toggleAuthFrameDisplay}/>
					</button>
					&nbsp;
					<button className="Action-button" style={{ backgroundColor: '#9b6a6a' }}>
						<CartIcon isAuthorized={!!user}
											inWishlist={product?.in_wishlist}
											itemId={product?.id}
											toggleAuthForm={toggleAuthFrameDisplay}/>
					</button>
					&nbsp;
          <Link to={!!user ? '/chats/' : '#'} onClick={() => { return user ? undefined : toggleAuthFrameDisplay() }}
								state = {{
									title: "Мир кружек",
									product: "Кружка",
									lastMessage: ""
								}}>
            <button className="Action-button"style={{ backgroundColor: '#66675e' }}>Написать продавцу</button>
          </Link>
					&nbsp;
					<Link to={`/store/`}>
					{/* <Link to={`/store/${props.store}`}> */}
            <button className="Action-button" style={{ backgroundColor: '#66675e' }}> Перейти в магазин</button>
          </Link>
				</div>
			</div>
			<div className="Product-page-detailed-info">
				<h2>Описание</h2>
				<p className="Product-page-product-description">{product?.description}</p>
			</div>
			{ 
      <ModalWindow isVisible={authFrameDisplay} closeFunction={()=>{setAuthFrameDisplay(!authFrameDisplay)}}>
        <AuthForms />
      </ModalWindow>
    }
		</>
	)
}