import { useEffect, useState } from "react"

import { useAuth } from "../../../context/AuthProvider"
// import { getUser, login } from "../services/backend"


export function LoginForm({ toRegisterForm }) {
  const { login } = useAuth()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(false);


  const submitForm = () => {
    login(username, password).then(
    )
    .catch(
      
    )
  }

	return (
    <>
      <h2>Вход</h2>
      <input className="Default-input Auth-form-input" type="text" placeholder="email или номер телефона"
             value={username} onChange={(e) => setUsername(e.target.value)} />
      <br />
      <br />
      <input className="Default-input Auth-form-input" type="text" placeholder="пароль"
             value={password} onChange={(e) => setPassword(e.target.value)} />
      <br />
      <input type="checkbox"checked={checked} onChange={e => setChecked(e.target.checked)} />  Запомнить пароль
      <br />
      <div className="Buttons-row">
        <button className="Action-button" style={{ backgroundColor: '#9b6a6a' }} onClick={ submitForm }>Войти</button>
        <button className="Action-button" style={{ backgroundColor: '#66675e' }} onClick={()=>{toRegisterForm()}}>Регистрация</button>
      </div>
    </>
	)
}
