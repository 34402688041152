import { fetchData } from "src/services/backendClient"
import { useQuery } from "@tanstack/react-query"

export function StoreInfo() {

  const { data: store, isLoading: storeLoading } = useQuery({
    queryKey: ['storeDetail'],
    queryFn: () => fetchData('stores/detailed'),
  })

  return (
    <>
      <div className="Account-header-grid">
        <div className='Account-header-grid-icon'>
          {
            store?.icon ? (
              <img src={store.icon} alt="icon" />
            ) : (
              <div className='No-icon'></div>
            )
          }
        </div>
        <div>
          <h3>{ store?.title }</h3>
        </div>
      </div>
      <div className=''>
        <h3>Описание:</h3>
        <p>{ store?.description }</p>
      </div>
      <div className=''>
        <h3>Товары:</h3>
      </div>
    </>
  )
}