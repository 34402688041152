import { Link } from "react-router-dom"
import { WishlistIcon } from "src/features/home/components/WishlistIcon"

export function ProductCard({ product }) {
  return (
    <div className="Row" style={{ width: '100%', height: '200px', marginTop: '20px', backgroundColor: '#c4a988', padding: '3px' }}>
        <Link to={`/products/${product.slug}`}>
          <img src={product.photos?.at(0)?.image} style={{height: '100%', width: '100%', objectFit: 'cover'}} alt="?"/>
        </Link>
        <div className='Product-card-data' style={{height: '40%', margin: '5px', /*backgroundColor: 'gray'*/}}>
          <p className='Product-card-title'>{product.name}</p>
          <p className='Product-card-price'>
            <span>{product.price}</span>
            &nbsp;
            <span>руб.</span>
          </p>
          <div className="Row" style={{ marginTop: '0px' }}>
          { <WishlistIcon isAuthorized={true}
                          inWishlist={product.in_wishlist}
                          itemId={product.id} /> }
          </div>
        </div>
    </div>
  )
}

ProductCard.defaultProps = {
  isAuthorized: false
}